import { Link } from "gatsby"
import React, { useState } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"


import Seo from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import getLanguage from "../utilt/getLangueage"
import useWindowSize from "../utilt/useWindowSize"

//CSS
import { panelWrappaer } from "./works.module.css"
import { panel } from "./works.module.css"
import { panelImage } from "./works.module.css"
import { panelTitles } from "./works.module.css"
import { panelTitle } from "./works.module.css"
import { desc } from "./works.module.css"
import { panelsHolizon } from "./works.module.css"
import { panels } from "./works.module.css"
//CSS2
import { title } from "./regularPage.module.css"
import { titleWorks } from "./regularPage.module.css"


//**********************************************************

/**
 * パネル・コンポーネント
 */
const Panel = props => {

    let pic = props.picL;
    if (props.winSize.width < 560) pic = props.picS;

    return (
        <div
            className={panelWrappaer}
        >
            <Link
                to={props.goto}
                className={panel}
            >
                <GatsbyImage
                    image={pic.childImageSharp.gatsbyImageData}
                    alt={pic.base.split(".")[0]}
                    className={panelImage} />
                <div
                    className={panelTitles}
                >
                    <div
                        className={panelTitle}
                        style={{
                            marginTop: `${props.winSize.width > 480 ?
                                60 - Math.max((String(props.title).length * 2.5 - 50), 0)
                                : 50 * (props.winSize.width / 480) ** 4}px`,
                            fontSize: `${props.winSize.width > 480 ? 3.5 : 2.25 + 1.25 * (props.winSize.width / 480) ** 4}rem`,
                            letterSpacing: `${0.025 + 0.325 * Math.min((props.winSize.width / 800) ** 4, 1)}rem`
                        }}
                    >
                        {props.title}
                    </div>
                    <div
                        className={desc}
                        style={{
                            marginTop: `${props.winSize.width > 860 ?
                                Math.max((String(props.title).length * 2.5 - 50), 0) :
                                props.winSize.width > 480 ?
                                    Math.max((String(props.title).length * 4.25 - 50), 0)
                                    : 50 + Math.max((String(props.title).length * 2.5 - 50), 0)}px`,
                        }}>
                        {props.desc}
                    </div>
                </div>
            </Link >
        </div >
    );
}



/**
 * ページ
 */
const Works = (props) => {
    const [lang, setLang] = useState(getLanguage());
    function setLanguage(enOrJa) {
        setLang(enOrJa);
        localStorage.setItem("site_Language", enOrJa);
    }
    const winSize = useWindowSize() || props.size || { width: 640, height: 948 }

    //画像データ取得
    const data = useStaticQuery(graphql`query MyQueryWorks {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "works"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
}
`)
    //1 取得した画像データを配列化
    const imagesOld = data.allFile.edges.map(n => n.node);
    const images = imagesOld.slice();
    //console.log(images);
    //2 base(=file name)で変数にbind
    let L_1plus31, S_1plus31, L_4, S_4, L_funnyDoll1000, S_funnyDoll1000,
         L_drawLinesAndDrawing, S_drawLinesAndDrawing, L_linesAndColors, S_linesAndColors,
        L_superCompressedLiteraryIllustration, S_superCompressedLiteraryIllustration,
        //L_oldWorks, S_oldWorks,
        L_ConnectedDrawingsCG,
        S_ConnectedDrawingsCG,
        L_atopic,
        S_atopic,
        L_grids,
        S_grids,
        L_connected,
        S_connected,
        L_otherDrawings,
        S_otherDrawings,
        L_installationView,
        S_installationView;
    for (let img of images) {
        let base = img.base;
        switch (base) {
            case "works_1plus31_L.jpg": L_1plus31 = img; break;
            case "works_1plus31_S.jpg": S_1plus31 = img; break;
            case "works_4_L.jpg": L_4 = img; break;
            case "works_4_S.jpg": S_4 = img; break;
            case "works_funnyDoll1000_L.jpg": L_funnyDoll1000 = img; break;
            case "works_funnyDoll1000_S.jpg": S_funnyDoll1000 = img; break;
            //case "works_oldWorks_L.jpg": L_oldWorks = img; break;
            //case "works_oldWorks_S.jpg": S_oldWorks = img; break;
            case "works_drawLinesAndDrawing_L.jpg": L_drawLinesAndDrawing = img; break;
            case "works_drawLinesAndDrawing_S.jpg": S_drawLinesAndDrawing = img; break;
            case "works_linesAndColors_L.jpg": L_linesAndColors = img; break;
            case "works_linesAndColors_S.jpg": S_linesAndColors = img; break;
            case "works_superCompressedLiteraryIllustration_L.jpg": L_superCompressedLiteraryIllustration = img; break;
            case "works_superCompressedLiteraryIllustration_S.jpg": S_superCompressedLiteraryIllustration = img; break;
            case "works_ConnectedDrawingsCG_L.jpg": L_ConnectedDrawingsCG = img; break;
            case "works_ConnectedDrawingsCG_S.jpg": S_ConnectedDrawingsCG = img; break;
            case "works_atopic_L.jpg": L_atopic = img; break;
            case "works_atopic_S.jpg": S_atopic = img; break;
            case "works_grids_L.jpg": L_grids = img; break;
            case "works_grids_S.jpg": S_grids = img; break;
            case "works_connected_L.jpg": L_connected = img; break;
            case "works_connected_S.jpg": S_connected = img; break;
            case "works_otherDrawings_L.jpg": L_otherDrawings = img; break;
            case "works_otherDrawings_S.jpg": S_otherDrawings = img; break;
            case "works_installationView_L.jpg": L_installationView = img; break;
            case "works_installationView_S.jpg": S_installationView = img; break;
        
            default: ;
        }
    }


    return (
        <Layout
            checked="works"
            winSize={winSize}
        >
            <Seo
                title="Works"
                description={lang !== "ja" ?
                    "３'s Works" :
                    "３の作品"
                }
                lang={lang}
                image="https://www.drawinghell.com/ogp_images/seo_works.jpg"
                pathname="/works"
            />
            <Article
                isLangBar="true"
                winSize={winSize}
                setLang={setLanguage}
            >
                <h1 className={`${title} ${titleWorks}`}>Works</h1>
                <div className={panelsHolizon}>

                    <div className={panels}>



                        <Panel
                            winSize={winSize}
                            goto="/work/superCompressedLiteraryIllustration"
                            picL={L_superCompressedLiteraryIllustration}
                            picS={S_superCompressedLiteraryIllustration}
                            title={
                                lang !== "ja" ?
                                    "Super-compressed Literary Illustration" :
                                    "超縮約文学イラスト"
                            }
                            desc={
                                lang !== "ja" ?
                                    `Illustration 2023-` :
                                    `イラスト　2023年～`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/drawLinesAndDrawing"
                            picL={L_drawLinesAndDrawing}
                            picS={S_drawLinesAndDrawing}
                            title={
                                lang !== "ja" ?
                                    `draw lines / Drawing` :
                                    `線を引く／Drawing`
                            }
                            desc={
                                lang !== "ja" ?
                                    `ink/ sumi-ink on paper 2022-` :
                                    `インク／墨、紙　2022年～`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/linesAndColors"
                            picL={L_linesAndColors}
                            picS={S_linesAndColors}
                            title={"Lines and Colors (GIF)"
                            }
                            desc={
                                lang !== "ja" ?
                                    `GIF animation 2021` :
                                    `GIFアニメ　2021年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/1plus31"
                            picL={L_1plus31}
                            picS={S_1plus31}
                            title="1(+31)"
                            desc={
                                lang !== "ja" ?
                                    `1 koma Manga, Digital Picture Book 2019` :
                                    `一コマ漫画、デジタル絵本　2019年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/4"
                            picL={L_4}
                            picS={S_4}
                            title="4"
                            desc={
                                lang !== "ja" ?
                                    `4 koma Manga, Book 2012` :
                                    `四コマ漫画、本　2012年`
                            } />


                        <Panel
                            winSize={winSize}
                            goto="/work/connected_CG"
                            picL={L_ConnectedDrawingsCG}
                            picS={S_ConnectedDrawingsCG}
                            title={
                                lang !== "ja" ?
                                    `Connected Drawing(CG)` :
                                    ` 繋ぎ絵(CG)`
                            }
                            desc={
                                lang !== "ja" ?
                                    `Digital Drawing 2006-2010` :
                                    `デジタルドローイング　2006-2010年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/atopic"
                            picL={L_atopic}
                            picS={S_atopic}
                            title={`atopic`}
                            desc={
                                lang !== "ja" ?
                                    `ink and gesso on paper clay 2009` :
                                    `インク、ジェッソ、紙粘土　2009年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/grids"
                            picL={L_grids}
                            picS={S_grids}
                            title={
                                lang !== "ja" ?
                                    `Grids Drawing` :
                                    `グリッド絵`
                            }
                            desc={
                                lang !== "ja" ?
                                    `ink on paper 2002-` :
                                    `インク、紙　2002年～`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/connected"
                            picL={L_connected}
                            picS={S_connected}
                            title={
                                lang !== "ja" ?
                                    `Connected Drawing` :
                                    ` 繋ぎ絵`
                            }
                            desc={
                                lang !== "ja" ?
                                    `ink on paper 2000-2002` :
                                    `インク、紙　2000-2002年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/otherDrawings"
                            picL={L_otherDrawings}
                            picS={S_otherDrawings}
                            title={
                                lang !== "ja" ?
                                    `Other Drawings in the 2000s` :
                                    `その他のドローイング`
                            }
                            desc={
                                lang !== "ja" ?
                                    `2002-2007` :
                                    `2002-2007年`
                            } />

                        <Panel
                            winSize={winSize}
                            goto="/work/funnyDoll1000"
                            picL={L_funnyDoll1000}
                            picS={S_funnyDoll1000}
                            title={`Funny Doll 1000`}
                            desc={
                                lang !== "ja" ?
                                    `pencil on paper 1997-1999` :
                                    `鉛筆、紙　1997-1999年`
                            } />


                        <Panel
                            winSize={winSize}
                            goto="/work/installationView"
                            picL={L_installationView}
                            picS={S_installationView}
                            title={
                                lang !== "ja" ?
                                    `Installation Views` :
                                    `展示風景`
                            }
                            desc={""} />


                    </div>
                </div>
            </Article>
        </Layout>
    )
}

export default Works


/*
//100000 doodles
                        <Panel
                            winSize={winSize}
                            goto="/work/oldWorks"
                            picL={L_oldWorks}
                            picS={S_oldWorks}
                            title={
                                lang !== "ja" ?
                                    `Drawing works in the 2000s` :
                                    `2000年代のドローイング`
                            }
                            desc={""} />

                            
                        <Panel2
                            winSize={winSize}
                            goto="https://twitter.com/drawinghell_2"
                            picL={L_ohtds}
                            picS={S_ohtds}
                            title={
                                lang !== "ja" ?
                                    `100000 doodles` :
                                    `100000 doodles`
                            }
                            desc={
                                lang !== "ja" ?
                                    `Digital Drawing 2010-(ongoing)` :
                                    `デジタルドローイング　2010年～（進行中）`
                            } />

                            const Panel2 = props => {

    let pic = props.picL;
    if (props.winSize.width < 560) pic = props.picS;

    return (
        <div
            className={panelWrappaer}
        >
            <a
                href={props.goto}
                target="_blank"
                rel="noopener noreferrer"
                className={panel}
                style={{
                    backgroundImage: `url(${props.bg
                        })`,
                    borderColor: props.borderColor
                }}
            >
                <GatsbyImage
                    image={pic.childImageSharp.gatsbyImageData}
                    alt={pic.base.split(".")[0]}
                    className={panelImage} />
                <div
                    className={panelTitles}
                >
                    <div
                        className={panelTitle}
                        style={{
                            marginTop: `${props.winSize.width > 480 ? 50 : 50 * (props.winSize.width / 480) ** 4}px`,
                            fontSize: `${props.winSize.width > 480 ? 3.5 : 2.25 + 1.25 * (props.winSize.width / 480) ** 4}rem`,
                            letterSpacing: `${0.025 + 0.325 * Math.min((props.winSize.width / 800) ** 4, 1)}rem`
                        }}
                    >
                        {props.title}
                    </div>
                    <div className={desc}>
                        {props.desc}
                    </div>

                </div>
            </a>
        </div>
    );
}
                        
*/