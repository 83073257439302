// extracted by mini-css-extract-plugin
export var desc = "works-module--desc--9T9bX";
export var desc2 = "works-module--desc2--uriwj";
export var eachTitle = "works-module--eachTitle--dawn2";
export var panel = "works-module--panel--+OhjW";
export var panelImage = "works-module--panelImage--Spg9G";
export var panelTitle = "works-module--panelTitle--szv8R";
export var panelTitle2 = "works-module--panelTitle2--cFMmX";
export var panelTitles = "works-module--panelTitles--tpYKc";
export var panelWrappaer = "works-module--panelWrappaer--pZOp6";
export var panels = "works-module--panels--8XGHt";
export var panelsHolizon = "works-module--panelsHolizon--wmDYT";
export var title = "works-module--title--yVTEU";